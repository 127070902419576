import React from "react"
import PageTemplate from '../components/pageTemplate'
import dips from '../data/dips'
import ProductDisplay from '../components/product-display'
import { graphql } from 'gatsby'

export default ({data}) => (
  <PageTemplate title="Dip Range" img={data.fileName}>
    <div className="text-center">
      <h5>Raybek Foods Gourmet Dip range is the ultimate party starter!</h5>
      <p>With a huge selection of 16 different flavours to choose from, even the fussiest of guests will be satisfied. From traditional Hummus and Tzatziki to exclusive Middle Eastern taste Baba Ganoush, all dips utalise the finest ingredients as well as herbs and spices create flavours that are second to none.</p>
      <p>All gourmet dips are 100% gluten free and range from retail 180g size to bulk 10kg size.</p>
      <p>Try our Gourmet Dip range today, and satisfy your taste buds!</p>
      <p>Additional flavours available on request</p>
    </div>
    <ProductDisplay products={dips}/>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "dips-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
